<template>
  <div class="container">
    <div>
      <h1>Your Own Cattle</h1>
      <div>
        <div>
          <img src="https://placehold.co/444x250" />
          <p class="absolute text-2xl top-1 left-3">{{ animal.externalId }}</p>
        </div>
        <div>
          <p>Two great options to sponsor this animal:</p>
          <table>
            <tr>
              <td><input type="radio" v-model="amount" :value="100" /></td>
              <td>100 € per month</td>
            </tr>
            <tr>
              <td><input type="radio" v-model="amount" :value="1000" /></td>
              <td>1000 € per year (2 months free)</td>
            </tr>
          </table>
          <p>Your contribution pays for labor, feed and medical bills.</p>
          <button>Pay Sponsorship</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { axiosInstance } from '@/library'

export default {
  name: 'YourOwnCattle',

  data: () => ({
    animal: {},
    amount: 100
  }),

  beforeMount() {
    this.loadAnimal()
  },

  methods: {
    loadAnimal() {
      const animalId = this.$route.params.animalId
      console.log('animalID', animalId)

      axiosInstance.get(`${window.VUE_APP_GRANJA}/api/animals/${animalId}`)
        .then(response => {
          if (response.status === 200) {
            this.animal = response.data
          } else
            this.animal = {}
        })
    },
  }
}
</script>

<style scoped>
TABLE {
  border-collapse: separate;
  border-spacing: 20px 0;
}

TH {
  text-align: left
}
</style>
